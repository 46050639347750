'use client';
import {useEffect} from 'react'
import {fatal} from 'src/actions/alerting'
import Config from 'src/config'
import once from 'sugar/function/once'
import GeneralErrorView from '@agp/ui/sections/error/general-error-view'

const ErrorBoundary = ({error, reset})=> {
	if (process.env.NODE_ENV === 'production') {
		reportOnce(`[${Config().LOCATION_NAME}] ${error.message}`)
	}
	return <GeneralErrorView />
}

const reportOnce = once(fatal)

export default ErrorBoundary